<template>
<!-- 首页管理页 -->
  <div class="home-manage">
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {getStore} from '../../js/utils/store'
import { validatenull } from '../../js/utils/validate'
import {
  getPropertyType
} from '../../api/exchange.js'
export default {
  data() {
    return {
      menus: [
        {name: '首页', index: '/home/homepage'},
        {
          name: '交易项目',
          index: '/home/exchange',
          children: [
            // {name: '耕地经营权', index: '/home/exchange'},
            // // {name: '草地承包经营权', index: '/home/exchange'},
            // {name: '林权', index: '/home/exchange'},
            // {name: '四荒地使用权', index: '/home/exchange'},
            // {name: '宅基地使用权', index: '/home/exchange'},
            // {name: '农房使用权', index: '/home/exchange'},
            // {name: '集体建设用地使用权', index: '/home/exchange'},
            // {name: '农业生产设施设备', index: '/home/exchange'},
            // {name: '农业类知识产权', index: '/home/exchange'},
            // {name: '园地经营权', index: '/home/exchange'},
            // {name: '小型水利工程使用权', index: '/home/exchange'},
          ]
        },
        {
          name: '交易公告',
          index: '/home/announcement',
          children: [
            {name: '需求信息公告', index: '/home/announcement'},
            {name: '成交信息公告', index: '/home/announcement'},
            // {name: '中标公示', index: '/home/announcement'},
            {name: '资金监管公示', index: '/home/announcement'},
            {name: '审核备案结果公告', index: '/home/announcement'},
          ]
        },
        {name: '农村金融', index: '/home/finance'},
        {name: '农村产改', index: '/home/productionChange'},
        {
          name: '技术服务',
          index: '/home/service',
          children: [
            {name: '土地价值评估', index: '/home/service'},
            {name: '土地测绘', index: '/home/service'},
            {name: '经纪人', index: '/home/service'},
            {name: '线上磋商', index: '/home/service'},
          ]
        },
        {
          name: '下载专区',
          index: '/home/download',
          children: [
            {name: '政策法规', index: '/home/download'},
            {name: '交易方法', index: '/home/download'},
            {name: '资料文档', index: '/home/download'},
          ]
        },
        {
          name: '村社发展治理',
          index: '/home/developmentManage',
          children: [
            {name: '基层党组织建设', index: '/home/developmentManage'},
            {name: '村民代表大会', index: '/home/developmentManage'},
            {name: '农村集体经济组织', index: '/home/developmentManage'},
          ]
        },
        {
          name: '关于我们',
          index: '/home/aboutUs',
          children: [
            {name: '公司简介', index: '/home/aboutUs'},
            {name: '联系我们', index: '/home/aboutUs'},
            {name: '组织架构', index: '/home/aboutUs'},
            {name: '互动提问', index: '/home/aboutUs'},
          ]
        },
      ],
      activeIndex: '/home/homepage',
      key: Math.random(1),
      refreshLock: false, // 刷新token锁
      refreshTime: null,
    }
  },
  watch: {
    $route: {
      handler: function() {
        this.activeIndex = this.$route.fullPath;
      },
      immediate: true,
      // deep: true, //深度监听，同时也可监听到param参数变化
    }
  },
  computed: mapGetters(['userInfo', 'expires_in']),
  created() {
    // 实时检测刷新token
    this.initProType();
    this.refreshToken();
    let token = getStore({name: 'access_token'});
    if(token) {
      this.refreshUserinfo();
    }
  },
  destroyed() {
    clearInterval(this.refreshTime)
  },
  methods: {
    refreshToken() {
      // 实时检测刷新token
      this.refreshTime = setInterval(() => {
        const token = getStore({
          name: 'access_token',
          debug: true
        })
        if (this.validatenull(token)) {
          return
        }
        if (this.expires_in <= 1000 && !this.refreshLock) {
          this.refreshLock = true
          this.$store
            .dispatch('RefreshToken')
            .catch(() => {
              clearInterval(this.refreshTime)
            })
          this.refreshLock = false
        }
        this.$store.commit('SET_EXPIRES_IN', this.expires_in - 10)
      }, 10000)
    },
    validatenull(val) {
      // 判断为空
      return validatenull(val)
    },
    refreshUserinfo() {
      // 实时刷新用户信息状态
      let user = {
        username: this.userInfo.username
      }
      // this.$store.dispatch('GetUserInfo', user);
    },
    async initProType() {
      // 获取产权类别
      let res = await getPropertyType();
      let {status, data} = res;
      if(status) {
        data.map(item => {
          item.name = item.lx;
          item.index = '/home/exchange';
        })
        localStorage.setItem('types', JSON.stringify(data))
        // this.menus[1].children = data;
        this.$set(this.menus[1], 'children', data)
      }
    },
    handleSelect(indexpath) {
      // 选中菜单项
      this.activeIndex = indexpath;
      // sessionStorage.setItem('indexPath', indexpath);
    },
  }
}
</script>



<style lang="less" scoped>


.home-manage {
  padding-top: 54px;
  .menus-con {
    width: 100%;
    position: relative;
    z-index: 2;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.13);

  }
  .my-menus {
    width: 1200px;
    margin: 0 auto;
    text-align: left;
    padding-bottom: 28px;
    .el-menu-demo {
      display: inline-block;
    }
  }
  .content {
    background:#f2f2f2;
  }
}
</style>

<style lang="less">

.my-menus {
  .el-menu--horizontal>.el-submenu .el-submenu__title {
    color: #333333;
  }
  .el-menu--horizontal>.el-menu-item {
    color: #333333;
  }
  .el-menu-item.is-active {
    color: #00a0e9;
  }
  .el-menu-item {
    font-size: 18px;
  }
  .el-submenu__title {
    font-size: 18px;
  }
  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }
}
.el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title {
  color: #333333 !important;
}
</style>
